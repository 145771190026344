import React, { memo, useEffect } from "react";
import { TableRow } from ".";
import { useSelectViewModelContext } from "..";
import { useTableViewModelContext } from "./tableContext";

const TableRows = ({ table, areRowsSelectable }) => {
  const [tableData] = useTableViewModelContext();
  const [, selectDispatch] = useSelectViewModelContext();

  useEffect(() => {
    const ids = tableData[table].filtered.map(row => row.node[tableData[table].identifier]);
    selectDispatch({
      type: "SET_IDS",
      payload: { table, ids }
    })
  }, [tableData]);

  return (
    <React.Fragment>
      {tableData[table].filtered.map((row, idx) => {
        return (
          <TableRow
            key={row.node[tableData[table].identifier]}
            table={table}
            columns={tableData[table].columns}
            id={row.node[tableData[table].identifier]}
            row={row.node}
            index={idx}
            isSelectable={areRowsSelectable}
          />
        );
      })}
    </React.Fragment>
  );
};

export default memo(TableRows);
