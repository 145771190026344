import React, { useCallback, useReducer, useEffect, useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components";
import { colors } from "../../colors";
import {
  Section,
  PasswordContainer,
  PasswordInput,
  PasswordToggle,
  TextInput,
  ButtonLinkStyles,
  Button,
  ModalWrapper,
  Modal,
  ConfirmButtonWrapper,
  ConfirmText
} from "../shared";
import { MdRemoveRedEye } from "@react-icons/all-files/md/MdRemoveRedEye";
import { getCookie } from "../../utility";
import { ToastContainer, toast } from "react-toastify";

const GET_USER = gql`
  query ($cookie: String!) {
    userFromCookie(cookie: $cookie) {
      userId
      address
      city
      email
      firstName
      lastName
      notes
      phone
      state
      userType
      userStatus
      zipCode
      created
      expiresOn
      subscriptionId
      willRenew
    }
  }
`;

const UPDATE_USER = gql`
  mutation ($changes: UserInput!, $userId: ID!) {
    updateUser(changes: $changes, userId: $userId) {
      user {
        email
        firstName
        lastName
        phone
        address
        city
        state
        zipCode
        notes
      }
    }
  }
`;

const Form = styled.form`
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    & > Label {
      flex-direction: column;
    }
  }
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 5px 20px;
  box-sizing: border-box;
`;

const Text = styled.span`
  min-width: 90px;
  margin-right: 10px;
  text-align: left;
  line-height: 38px;
`;

const ProfileInput = styled(TextInput)`
  width: 100%;
`;

const Save = styled.button`
  ${ButtonLinkStyles}
  width: 90%;
  font-size: 18px;
  margin-bottom: 0;

  &[disabled] {
    background-color: ${colors.disabled};
  }
`;

const CancelSub = styled(Save)`
  background-color: ${colors.danger};
`;

const defaultState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  userType: "member",
  userStatus: "active",
  subscriptionId: "",
  notes: "",
  isSubmitting: false,
  dirtyFields: [],
  isModalVisible: false
};

const memberProfileDispatch = (state, action) => {
  switch (action.type) {
    case "SET_ALL": {
      return {
        ...action.payload
      };
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      if (!newState.dirtyFields.includes(action.payload.name)) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "RESET_DIRTY": {
      return {
        ...state,
        dirtyFields: []
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SET_SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const MemberProfile = () => {
  const [state, componentDispatch] = useReducer(memberProfileDispatch, defaultState);
  const userQuery = useQuery(GET_USER, {
    variables: {
      cookie: getCookie("uuid")
    },
    fetchPolicy: "no-cache"
  });

  const { loading, error, data } = useMemo(() => {
    return { ...userQuery };
  }, [userQuery.loading, userQuery.error, userQuery.data]);

  const [updateUser] = useMutation(UPDATE_USER);
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordType = useCallback(() => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }, [passwordType, setPasswordType]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const hideModal = useCallback(() => {
    componentDispatch({
      type: "SET_SHOW_MODAL",
      payload: false
    });
  }, []);

  const showModal = useCallback(() => {
    componentDispatch({
      type: "SET_SHOW_MODAL",
      payload: true
    });
  }, []);

  const handleCancelSubscription = useCallback(() => {
    fetch(`${process.env.REACT_APP_REST_ENDPOINT}/cancel`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify({ subscriptionId: state.subscriptionId })
    }).then((response) => {
      hideModal();
      response.json().then((json) => {
        if (response.ok) {
          userQuery.refetch();
          toast.success("Subscription has been cancelled.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        } else {
          toast.error(json.error, {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      });
    });
  }, [state.subscriptionId, hideModal]);

  useEffect(() => {
    if (data && data.userFromCookie) {
      const { userFromCookie } = data;
      componentDispatch({
        type: "SET_ALL",
        payload: Object.assign({ ...state }, { ...userFromCookie })
      });
    }
  }, [state.identifier, data]);

  const handleSave = useCallback((e) => {
    e.preventDefault();
    const changes = {};
    state.dirtyFields.map((field) => {
      changes[field] = state[field];
    });
    changes["lastUpdate"] = new Date().toString();
    componentDispatch({ type: "START_SAVE" });
    updateUser({
      variables: {
        changes,
        userId: state.userId
      }
    }).then(
      () => {
        componentDispatch({ type: "STOP_SAVE" });
        toast.success("Updated successfully.", {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
      },
      () => {
        toast.error("Something went wrong.", {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
      }
    );
  });
  return (
    <>
      <Section>
        <Form>
          <Label>
            <Text>Email</Text>
            <ProfileInput name="email" value={state.email} onChange={handleChange} />
          </Label>
          <Label>
            <Text>Password</Text>
            <PasswordContainer style={{ width: "100%" }}>
              <PasswordInput
                name="password"
                type={passwordType}
                value={state.password}
                placeholder="password"
                onChange={handleChange}
              />
              <PasswordToggle onClick={togglePasswordType}>
                <MdRemoveRedEye />
              </PasswordToggle>
            </PasswordContainer>
          </Label>
          <Label>
            <Text>First Name</Text>
            <ProfileInput name="firstName" value={state.firstName} onChange={handleChange} />
          </Label>
          <Label>
            <Text>Last Name</Text>
            <ProfileInput name="lastName" value={state.lastName} onChange={handleChange} />
          </Label>
          <Label>
            <Text>Phone</Text>
            <ProfileInput name="phone" value={state.phone} onChange={handleChange} />
          </Label>
          <Label>
            <Text>Address</Text>
            <ProfileInput name="address" value={state.address} onChange={handleChange} />
          </Label>
          <Label>
            <Text>City</Text>
            <ProfileInput name="city" value={state.city} onChange={handleChange} />
          </Label>
          <Label>
            <Text>State</Text>
            <ProfileInput name="state" value={state.state} onChange={handleChange} />
          </Label>
          <Label>
            <Text>Zip Code</Text>
            <ProfileInput name="zipCode" value={state.zipCode} onChange={handleChange} />
          </Label>
          <Label>
            <Text>{state.willRenew ? "Renews on" : "Expires on"}</Text>
            <Text>{state.expiresOn}</Text>
          </Label>
          <Save disabled={state.dirtyFields.length === 0} onClick={handleSave}>
            Save
          </Save>
          <CancelSub type="button" onClick={showModal} disabled={!state.willRenew}>
            Cancel Subscription
          </CancelSub>
        </Form>
        <ToastContainer autoClose={2000} />
      </Section>
      {state.isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Would you like to cancel your Brew Saver subscription?</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleCancelSubscription}>Yes</Button>
              <Button onClick={hideModal}>No</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
    </>
  );
};
