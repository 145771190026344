import React from "react";
import { BeerLoader } from ".";
import { colors } from "../../colors";
import { LoaderWrapper, LoaderBackground, LoaderText } from "../shared";

export const Loader = ({ size, text }) => (
  <LoaderWrapper>
    <LoaderBackground style={{ backgroundColor: colors.secondary }}>
      <BeerLoader height={size || 100} />
      {/* <LogoLoader size={size || 100} /> */}
      <LoaderText style={{ color: "white" }}>{text ? text : "Loading..."}</LoaderText>
    </LoaderBackground>
  </LoaderWrapper>
);
