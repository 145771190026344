import React from "react";
import { BrowserRouter } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { useUIThemeContext } from "@dewz/ui";
import { ThemeProvider } from "styled-components";
import { SessionProvider } from "./components/session/sessionContext";
import { TableProvider, SelectProvider, FilterProvider } from "./components/table";
import { SearchProvider } from "./components/member/searchContext";
import { AnchorProvider } from "./components/member/anchorContext";
import { ClientProvider } from "./components/client/clientContext";
import { VenueNavProvider } from "./components/member/venueContext";
import { VersionCheck } from "./components/version";
import Routes from "./components/routing/Routes";
import { getCookie } from "./utility";

function App() {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    headers: {
      AUTHTOKEN: getCookie("uuid")
    }
  });

  const [themeData] = useUIThemeContext();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={themeData.themes["light"]}>
        <SessionProvider>
          <TableProvider>
            <FilterProvider>
              <SelectProvider>
                <ClientProvider>
                  <BrowserRouter>
                    <AnchorProvider>
                      <SearchProvider>
                        <VenueNavProvider>
                          <VersionCheck />
                          <Routes />
                        </VenueNavProvider>
                      </SearchProvider>
                    </AnchorProvider>
                  </BrowserRouter>
                </ClientProvider>
              </SelectProvider>
            </FilterProvider>
          </TableProvider>
        </SessionProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
