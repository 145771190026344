import React, { useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../../colors";

const Notification = styled.div`
  position: absolute;
  width: 90%;
  left: 5%;
  box-sizing: border-box;
  top: 1rem;
  background-color: ${colors.danger};
  color: white;
  height: 200px;
  overflow-y: auto;
  padding: 1rem;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
`;

const CloseBtn = styled.button`
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  border: none;
  padding-top: 1.2rem;
  background-color: ${colors.danger};
`;

export const StatsNotification = ({ notificationId, message, onClose }) => {
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose(notificationId);
    }
  }, [onClose]);

  return (
    <Notification>
      {message}
      <CloseBtn onClick={handleClose}>CLOSE</CloseBtn>
    </Notification>
  );
}