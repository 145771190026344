export const colors = {
  primary: "#f0a500",
  primaryOffset: "white",
  primaryTransparent: "rgba(255, 120, 0, .8);",

  secondary: "#006edc",
  secondaryTransparent: "rgba(0, 110, 220, .8)",
  secondaryOffset: "white",
  secondaryShaded: "#004284",

  tableHeaderBg: "#006edc",
  tableHeaderFont: "#fff",
  tableOdd: "#f9f8ff",
  tableEven: "#f3f2ff",
  tableIcon: "#fff",
  tableIconBg: "#f0a500",

  defaultBg: "#f9f8ff",
  defaultBgOffset: "black",
  memberBg: "#f0a500",

  navBg: "white",
  navLinkBg: "#f0a500",
  navLinkFont: "white",
  memberIconContainerBg: "rgba(255, 255, 255, .5)",

  success: "green",
  danger: "red",

  disabled: "#aaaaaa",

  tableLink: "#0066ff",
  linkColor: "#0066ff",

  inputBorder: "#cccccc",
  required: "#f0a500",
  darkFont: "#333333",

  totalStats: "#c08502",
  primaryChange: "#c08502",

  goodMessage: "#3ad733",
  badMessage: "red",
  middleMessage: "#f0a500",

  goodBackground: "#37c231"
};
