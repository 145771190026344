import React, { createContext, useContext, useReducer } from "react";

export const searchViewModel = createContext();
searchViewModel.displayName = "Search Context";
export const useSearchContext = () => {
  return useContext(searchViewModel);
};

const defaultState = {
  venueName: null,
  names: [],
  venueArea: [],
  areas: [],
  venueCity: [],
  cities: [],
  offerType: [],
  offerTypes: [],
  daysAvailable: [],
  filter: {
    venueName: null,
    venueArea: [],
    venueCity: [],
    offerType: [],
    daysAvailable: [],
    hasBeenClicked: false
  }
};

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      return newState;
    }
    case "SET_SEARCH_VALUES": {
      const values = action.payload;
      const filter = Object.assign({}, state.filter, values);
      const newState = { ...state, filter };
      return newState;
    }
    default:
      return { ...state };
  }
};

export const SearchProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <searchViewModel.Provider value={[state, dispatch]}>{children}</searchViewModel.Provider>;
};
