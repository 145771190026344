import React from "react";
import styled from "styled-components";

const CopyrightText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
`;

export const Copyright = ({ color, style }) => {
  return <CopyrightText style={{ ...style, color }}>Copyright &#169; O'Keefe Publishing, Inc.</CopyrightText>;
};
