import React from "react";
import styled from "styled-components";
import { colors } from "../../colors";
import { Link } from "react-router-dom";

const fontSize = "14px";
const margin = "3px 5px";
const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: none;
  border: none;
  cursor: pointer;
  font-size: ${fontSize};
  padding: 0;
  margin: ${margin};
  border-radius: 3px;
  box-shadow: 1px 1px 1px black;
`;

const IconWrapper = styled.div`
  height: 3em;
  width: 3em;
  background-color: ${colors.tableIconBg};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: ${colors.tableIcon};
`;

const ButtonLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: ${fontSize};
  padding: 0;
  margin: ${margin};
  border-radius: 3px;
  box-shadow: 1px 1px 1px black;
`;

export const TableButton = ({ icon, text, handleClick, link, children }) => {
  if (link) {
    return (
      <ButtonLink to={link} onClick={handleClick} title={text}>
        <IconWrapper>{icon}</IconWrapper>
        {children}
      </ButtonLink>
    );
  }

  return (
    <Button onClick={handleClick} title={text}>
      <IconWrapper>{icon}</IconWrapper>
      {children}
    </Button>
  );
};
