import React from "react";
import styled from "styled-components";

const WelcomeText = styled.span`
  font-size: 30px;
  font-weight: bold;
`;

export const MemberWelcome = ({ first, last }) => {
  return (
    <>
      <WelcomeText>Welcome</WelcomeText>
      <br />
      <WelcomeText>{`${first} ${last}`}</WelcomeText>
    </>
  );
};
