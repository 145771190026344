import React, { useEffect, useState, useCallback } from "react";
import { Button, ConfirmText, Modal, ConfirmButtonWrapper, ModalWrapper } from "../shared";

export const VersionCheck = () => {
  const [showRefresh, setRefresh] = useState(false);

  const fetchVersion = useCallback(() => {
    fetch(`${process.env.REACT_APP_VERSION_ENDPOINT}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer"
    }).then(
      (response) =>
        response.json().then((data) => {
          console.log(process.env.REACT_APP_CURRENT_VERSION);
          if (data?.version !== process.env.REACT_APP_CURRENT_VERSION) {
            setRefresh(true);
          } else {
            setRefresh(false);
          }
        }, console.log),
      console.log
    );
  }, [setRefresh]);

  useEffect(() => {
    fetchVersion();
    const interval = setInterval(fetchVersion, 1800000);
    return () => window.clearInterval(interval);
  }, [fetchVersion]);

  // const hideModal = useCallback(() => setRefresh(false), [setRefresh]);

  // const handleRefresh = useCallback(() => {
  //   console.log("handle refresh");
  //   window.close
  //   window.close();
  //   hideModal();
  // }, [hideModal]);

  return (
    <>
      {showRefresh && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>
              We have detected that your machine is running out of date files. Please update them by closing
              and re-opening the app.
            </ConfirmText>
          </Modal>
        </ModalWrapper>
      )}
    </>
  );
};
