import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import { colors } from "../../colors";

const AlertWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;
  width: 100%;
  color: ${colors.secondaryOffset};
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.secondaryOffset};
  transition: all 1s;
`;

const AlertWidgetTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  color: ${colors.secondaryOffset};
`;

const AlertWidgetToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
  color: ${colors.secondaryOffset};
  box-sizing: border-box;
  padding: 5px 0;
`;

const AlertWidgetToggleIcon = styled(MdKeyboardArrowDown)`
  transform: ${({ isActive }) => (isActive ? "none" : "rotate(180deg)")};
`;

const AlertWidgetContent = styled.div`
  background-color: ${colors.secondaryShaded};
  padding: 10px;
  margin-bottom: 10px;
  display: ${({ display }) => display};
  box-shadow: 1px 2px ${colors.secondaryOffset};
  text-align: left;
  position: relative;
  font-weight: 500;
  font-size: 20px;
`;

export const AlertWidget = ({ title, content, isActive }) => {
  const [active, setActive] = useState(isActive ? true : false);

  const toggleActiveOff = useCallback(() => {
    setActive(false);
  }, [setActive]);

  const toggleActiveOn = useCallback(() => {
    setActive(true);
  }, [setActive]);

  return (
    <AlertWidgetWrapper>
      <AlertWidgetToggle onClick={active ? toggleActiveOff : toggleActiveOn}>
        <AlertWidgetTitle>
          {typeof title === "string"
            ? title.split("<br>").map((line) => (
                <>
                  {line}
                  <br />
                </>
              ))
            : content}
        </AlertWidgetTitle>
        <AlertWidgetToggleIcon isActive={active} size="35px" />
      </AlertWidgetToggle>
      <AlertWidgetContent display={active ? "block" : "none"}>
        {typeof content === "string"
          ? content.split("<br>").map((line) => (
              <>
                {line}
                <br />
              </>
            ))
          : content}
      </AlertWidgetContent>
    </AlertWidgetWrapper>
  );
};
