import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { AlertWidget } from "../";
import { colors } from "../../colors";
import { BaseInputStyles, Button, Label, SubmitButton, Text, TextInput } from "../shared";

const DELETE_SETTINGS = gql`
  mutation ($ids: [ID]!) {
    deleteSettingss(ids: $ids) {
      success
    }
  }
`;

const GET_SETTINGS = gql`
  query {
    settings {
      edges {
        node {
          settingsId
          group
          data
        }
      }
    }
  }
`;

const CREATE_SETTINGS = gql`
  mutation ($group: String!, $data: String!) {
    createSettings(group: $group, data: $data) {
      settings {
        group
        data
      }
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation ($changes: SettingsInput!, $settings_id: ID!) {
    updateSettings(settings_id: $settings_id, changes: $changes) {
      settings {
        settingsId
        group
        data
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  ${BaseInputStyles}
  width: ${({ width }) => width || "calc(100% - 140px)"};
  max-width: 500px;
  height: 100px;
`;

const defaultState = {
  header: "",
  body: "",
  notifications: [],
  isModalVisible: false,
  isDeleteVisible: false
};

const offerManagerDispatch = (state, action) => {
  switch (action.type) {
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      return newState;
    }
    case "SET_OFFER_TYPE_MANAGER_OPEN": {
      return {
        ...state,
        isOfferTypeManagerVisible: action.payload
      };
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "ADD_OFFER": {
      return {
        ...defaultState,
        identifier: undefined,
        dirtyFields: []
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SHOW_DELETE_MODAL": {
      return {
        ...state,
        isDeleteVisible: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const Settings = () => {
  const settingsQuery = useQuery(GET_SETTINGS, {
    fetchPolicy: "no-cache"
  });
  const [deleteSettings] = useMutation(DELETE_SETTINGS);
  const [createSettings] = useMutation(CREATE_SETTINGS);
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const [notifications, setNotifications] = useState([]);
  const [state, componentDispatch] = useReducer(offerManagerDispatch, Object.assign(defaultState));

  useEffect(() => {
    if (settingsQuery.data) {
      const notificationStrings = settingsQuery.data.settings.edges.filter(
        (setting) => setting.node.group === "notifications"
      );
      setNotifications(
        notificationStrings.map((element) => {
          const { node } = element;
          const data = JSON.parse(node.data);
          const retObj = {
            ...data,
            settingsId: node.settingsId,
            group: node.group
          };
          return retObj;
        })
      );
    }
  }, [settingsQuery.data]);

  const handleSubmit = useCallback(() => {
    // if (hasSettings) {
    //   updateSettings({
    //     variables: {
    //       changes: {
    //         whatsUpMessage
    //       }
    //     }
    //   }).then(
    //     () => {
    //       toast.success("Updated successfully.", {
    //         position: "bottom-center",
    //         hideProgressBar: true,
    //         pauseOnHover: false,
    //         closeButton: false
    //       });
    //     },
    //     () => {
    //       toast.error("Something went wrong.", {
    //         position: "bottom-center",
    //         hideProgressBar: true,
    //         pauseOnHover: false,
    //         closeButton: false
    //       });
    //     }
    //   );
    // } else {
    createSettings({
      variables: {
        group: "notifications",
        data: JSON.stringify({ header: state.header, body: state.body })
      }
    }).then(
      () => {
        toast.success("Message set successfully.", {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
        settingsQuery.refetch();
      },
      () => {
        toast.error("Something went wrong.", {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
      }
    );
    // }
  }, [updateSettings, createSettings, state]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const handleLineBreak = useCallback(
    (e) => {
      const name = e.target?.dataset["name"];
      const value = state[name] + "<br>";
      handleChange({ target: { name, value } });
    },
    [handleChange, state]
  );

  const handleDelete = useCallback(
    (e) => {
      const id = e.target?.dataset?.id;
      deleteSettings({
        variables: {
          ids: [id]
        }
      }).then(
        () => {
          toast.success("Notification deleted successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          settingsQuery.refetch();
        },
        () => {
          toast.error("Something went wrong.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    },
    [deleteSettings]
  );

  return (
    <Wrapper>
      <h2>Manage Notifications</h2>
      <section style={{ width: "100%", padding: "1rem" }}>
        <Label required>
          <Text>Header</Text>
          <TextInput
            required
            name="header"
            value={state.header}
            onChange={handleChange}
            //data-required={missingFields.includes("header") && state.header?.length === 0}
          />
          <Button data-name="header" type="button" onClick={handleLineBreak}>
            Line Break
          </Button>{" "}
        </Label>

        <Label required>
          <Text>Body</Text>
          <TextArea
            required
            name="body"
            value={state.body}
            onChange={handleChange}
            //data-required={missingFields.includes("body") && state.body?.length === 0}
          />
          <Button data-name="body" type="button" onClick={handleLineBreak}>
            Line Break
          </Button>{" "}
        </Label>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <SubmitButton value="Add Notification" onClick={handleSubmit} style={{ justifySelf: "center" }} />
        </div>
      </section>
      <section style={{ width: "100%", padding: "1rem", backgroundColor: colors.secondary }}>
        {notifications.map((notification) => {
          return (
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                alignItems: "center",
                paddingRight: "40px",
                boxSizing: "border-box"
              }}
            >
              <AlertWidget title={notification.header} content={notification.body} isActive={true} />
              <div
                style={{
                  color: colors.secondaryOffset,
                  position: "absolute",
                  right: "-10px",
                  height: "100%",
                  borderLeft: `1px solid ${colors.secondaryOffset}`
                }}
              >
                <div data-id={notification.settingsId} onClick={handleDelete} title="Delete Notification">
                  <MdDeleteForever size="2em" style={{ pointerEvents: "none" }} />
                </div>
              </div>
            </div>
          );
        })}
      </section>

      <ToastContainer />
    </Wrapper>
  );
};
