import React, { useState, useEffect, useCallback } from "react";
import { Section, SectionHeader } from "../../shared";
import styled from "styled-components";
import { colors } from "../../../colors";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getCookie } from "../../../utility";
import { StatsNotification } from "../stats/statsNotification";

const GET_VENUES_AND_OFFERS = gql`
  query {
    allActiveVenuesAndOffers {
      edges {
        node {
          venueId
          phone
          website
          address
          city
          state
          zipCode
          logo
          name
          area
          offers {
            offerId
            endDate
            header
            subheader
            description
            disclaimer
            offerType
            image
            maxValue
            redemptionPeriod
            allowedRedemptions
            startDate
            endDate
          }
        }
      }
    }
  }
`;

const GET_USER_REDEMPTIONS = gql`
  query ($cookie: String!) {
    userRedemptions(cookie: $cookie) {
      offerId
      maxValue
    }
  }
`;

const GET_MESSAGES = gql`
  query {
    allNotifications {
      notificationId
      message
      createdOn
    }
  }
`;

const GET_USER_NOTIFICATIONS = gql`
  query ($cookie: String!) {
    userNotifications(cookie: $cookie) {
      id
      notificationId
      userId
    }
  }
`;

const CREATE_USER_NOTIFICATION = gql`
  mutation ($notificationId: ID!, $cookie: String!) {
    createUserNotification(notificationId: $notificationId, cookie: $cookie) {
      userNotification {
        notificationId
        userId
      }
    }
  }
`;

const Divider = styled.div`
  height: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px 0;
`;

const fontSize = "18px";
const Text = styled.span`
  font-weight: 600;
  font-size: ${fontSize};
  color: ${({ color }) => color};
`;

const Value = styled.span`
  font-weight: 650;
  font-size: ${fontSize};
  color: ${({ color }) => color};
`;

const calculateNumberOfRedemptions = (offer) => {
  const redemptionPeriod = JSON.parse(offer?.redemptionPeriod)?.value;
  const endDate = new Date(offer.endDate);
  const end = endDate.getTime();
  const now = new Date().getTime();
  if (end > now) {
    const difference = endDate.getTime() - now;
    const numDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    switch (redemptionPeriod) {
      case "perDay": {
        return offer.allowedRedemptions * (numDays + 1);
      }
      case "perWeek": {
        return offer.allowedRedemptions * Math.ceil(numDays / 7);
      }
      case "perMonth": {
        return offer.allowedRedemptions * Math.ceil(numDays / 30.42);
      }
      case "perYear": {
        return offer.allowedRedemptions * Math.ceil(numDays / 365);
      }
      default:
        return 0;
    }
  }
  return 0;
};

export const OfferStats = () => {
  const [createUserNotification] = useMutation(CREATE_USER_NOTIFICATION);

  const messagesQuery = useQuery(GET_MESSAGES, {
    fetchPolicy: "no-cache"
  });

  const userNotificationQuery = useQuery(GET_USER_NOTIFICATIONS, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });

  const { data } = useQuery(GET_VENUES_AND_OFFERS, {
    fetchPolicy: "no-cache"
  });
  const userRedemptions = useQuery(GET_USER_REDEMPTIONS, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });
  const [venueOffers, setVenueOffers] = useState({});
  const [myRedemptions, setMyRedemptions] = useState({});
  const [myStats, setMyStats] = useState({});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (
      !messagesQuery.loading &&
      messagesQuery.data?.allNotifications &&
      !userNotificationQuery.loading &&
      userNotificationQuery.data?.userNotifications
    ) {
      const hiddenIds = userNotificationQuery.data.userNotifications.map(
        (notification) => notification.notificationId
      );
      const newMessages = messagesQuery.data.allNotifications.reduce((acc, curr) => {
        if (!hiddenIds.includes(curr.notificationId)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setMessages(newMessages);
    }
  }, [userNotificationQuery.data, messagesQuery.data]);

  useEffect(() => {
    let totalVenues = 0;
    let totalOffers = 0;
    let totalValue = 0;
    let allOffers = [];
    if (data) {
      data.allActiveVenuesAndOffers.edges.map((edge) => {
        let hasOfferAvailable = false;
        edge.node.offers.forEach((node) => {
          const numRedemptionLeft = calculateNumberOfRedemptions(node);
          totalOffers += numRedemptionLeft;
          totalValue += numRedemptionLeft * node.maxValue;
          allOffers.push(node.offerId);
          if (numRedemptionLeft > 0) {
            hasOfferAvailable = true;
          }
        });
        if (hasOfferAvailable) {
          totalVenues++;
        }
      });
    }
    setVenueOffers(
      Object.assign({
        totalVenues,
        totalOffers,
        totalValue,
        allOffers
      })
    );
  }, [data, setVenueOffers]);

  useEffect(() => {
    let offersRedeemed = [];
    if (userRedemptions.data) {
      offersRedeemed = userRedemptions.data.userRedemptions;
      setMyRedemptions({ offersRedeemed });
    }
  }, [userRedemptions]);

  // not working
  useEffect(() => {
    let mySavings = 0;
    // let offersAvailable = 0;
    // let valueAvailable = 0;
    let offers = [];
    if (data && data.allActiveVenuesAndOffers) {
      data.allActiveVenuesAndOffers.edges.forEach((edge) => {
        offers = offers.concat(edge.node.offers);
      });
    }
    if (offers && userRedemptions.data && userRedemptions.data.userRedemptions) {
      userRedemptions.data.userRedemptions.forEach((redemption) => {
        mySavings += offers.find((offer) => offer.offerId === redemption.offerId)?.maxValue || 0;
      });
    }
    setMyStats({ mySavings });
  }, [data, userRedemptions]);

  const handleClose = useCallback(
    (id) => {
      createUserNotification({
        variables: {
          cookie: getCookie("uuid"),
          notificationId: id
        }
      });
      const newMessages = [...messages];
      newMessages.splice(
        newMessages.findIndex((message) => message.id === id),
        1
      );
      setMessages(newMessages);
    },
    [messages]
  );

  return (
    <Section>
      <SectionHeader>Stats</SectionHeader>
      <Divider />
      <Text>
        Total Venues: <Value color={colors.totalStats}>{venueOffers.totalVenues}</Value>
      </Text>
      <Divider />
      <Text>
        Total Offers: <Value color={colors.totalStats}>{venueOffers.totalOffers}</Value>
      </Text>
      <Divider />
      <Text>
        Total Value: <Value color={colors.totalStats}>{`$${venueOffers.totalValue}`}</Value>
      </Text>
      <Divider />
      {/* <Text color="green"> */}
      <Text>
        Offers Redeemed:{" "}
        <Value color={colors.success}>
          {myRedemptions.offersRedeemed && myRedemptions.offersRedeemed.length}
        </Value>
      </Text>
      <Divider />
      {/* <Text color="green"> */}
      <Text>
        My Savings: <Value color={colors.success}>${myStats.mySavings}</Value>
      </Text>
      <Divider />
      {/* <Text color="red">
      <Text>
        Offers Available: <Value color={colors.danger}>93</Value>
      </Text>
      <Divider />
      <Text>
        Value Available: <Value color={colors.success}>$557.00</Value>
      </Text>
      <Divider /> */}
      {messages.map((message) => (
        <StatsNotification key={message.notificationId} {...message} onClose={handleClose} />
      ))}
    </Section>
  );
};
