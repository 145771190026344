import React, { createContext, useContext, useReducer } from "react";

export const clientViewModel = createContext();
clientViewModel.displayName = "Client Context";
export const useClientContext = () => {
  return useContext(clientViewModel);
};

const defaultState = {
  activeOffer: null
};

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_OFFER": {
      const values = action.payload;
      return Object.assign({ ...state }, values);
    }
    default:
      return { ...state };
  }
};

export const ClientProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <clientViewModel.Provider value={[state, dispatch]}>{children}</clientViewModel.Provider>;
};
