import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const LazyLogin = React.lazy(() => import("../login/login"));
const LazyCLientLanding = React.lazy(() => import("../client/landing"));
const LazyAdminLanding = React.lazy(() => import("../admin/landing"));
const LazyMemberLanding = React.lazy(() => import("../member/landing"));

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/admin" component={LazyAdminLanding} />
        <Route path="/client" component={LazyCLientLanding} />
        <Route path="/member" component={LazyMemberLanding} />
        <Route path="/:form" component={LazyLogin} />
        <Route default component={LazyLogin} />
      </Switch>
    </Suspense>
  );
};
export default Routes;
