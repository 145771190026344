import React from "react";
import styled from "styled-components";
import { colors } from "../../colors";
import bubblesBgPng from "../../assets/bubbles.png";
import { Section, Button } from "../shared";

const VenueImage = styled.img`
  display: block;
  margin: auto;
  padding-bottom: 10px;
  max-height: 250px;
  max-width: 250px;
`;

const VenueName = styled.h2`
    color: ${colors.primary};
    margin: 0;
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const VenueInfo = styled.span`
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const VenueLink = styled.a`
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 2px;
    line-height: 16px;
    color: ${colors.linkColor};
    word-break: break-all;
`;

const Phone = styled.a`
  text-decoration: none;
  color: inherit;
  word-break: break-all;
`;

const VenueWebsite = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  margin: 2px;
  color: ${colors.linkColor};
  word-break: break-all;
`;

const DisplayBackground = styled.div`
  position: fixed;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisplayWrapper = styled.div`
  position: fixed;
  background-image: url(${bubblesBgPng});
  background-size: cover;
  height: 600px;
  width: 400px;
  max-height: 70vh;
  max-width: 80vw;
  z-index: 3;
  overflow-y: scroll;
`;

const formatURL = (url) => {
  const prefix = "http://";
  const https = "https://";
  const subStr = url.substr(0, prefix.length);

  if (subStr !== prefix && subStr !== https) {
    url = prefix + url;
  }
  return url;
};

export const VenuePreview = ({ venue, onClose }) => {
  return (
    <DisplayBackground>
      <DisplayWrapper>
        <Section>
          <VenueImage key="logo" src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${venue.logo}`} />
          <VenueImage key="image" src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${venue.image}`} />
          <VenueName key="name">{venue.name}</VenueName>

          <Phone href={`tel:${venue.phone}`}>{venue.phone}</Phone>
          <VenueInfo key="website">
            <VenueWebsite href={formatURL(venue.website)} target="_blank">
              {venue.website}
            </VenueWebsite>
          </VenueInfo>
          <VenueLink
            target="_blank"
            key="address"
            href={`https://maps.google.com/?q=${venue.address},
              ${venue.city},
              ${venue.state} ${venue.zipCode}`}
          >
            {`${venue.address},`}
            <br />
            {`${venue.city},
              ${venue.state} ${venue.zipCode}`}
          </VenueLink>
        </Section>
        <Button onClick={onClose} style={{ width: "100%", position: "sticky", bottom: "-1px", margin: "0" }}>
          Close
        </Button>
      </DisplayWrapper>
    </DisplayBackground>
  );
};
