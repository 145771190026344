import React from "react";
import { useParams } from "react-router-dom";
import { VenueManager } from "../manager";

export const ClientRouter = () => {
  let { entity, identifier } = useParams();
  switch (entity) {
    case "venue":
      return <VenueManager identifier={identifier} isLimited={true} />;
    default:
      return <div>404 url not found</div>;
  }
};
