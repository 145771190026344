import React, { createContext, useContext, useReducer } from "react";

export const venueNavViewModel = createContext();
venueNavViewModel.displayName = "Venue Context";
export const useVenueNavContext = () => {
  return useContext(venueNavViewModel);
};

const defaultState = {
  venue: null
};

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_VENUE": {
      const venue = action.payload;
      return { venue };
    }
    default:
      return { ...state };
  }
};

export const VenueNavProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <venueNavViewModel.Provider value={[state, dispatch]}>{children}</venueNavViewModel.Provider>;
};
