import React, { useCallback, useEffect, useReducer, useState } from "react";
import Emoji from "react-emoji-render";
import { MdRemoveRedEye } from "@react-icons/all-files/md/MdRemoveRedEye";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import logo from "../../assets/brew_saver_logo_-_big.png";
import bubblesBgPng from "../../assets/bubbles.png";
import { colors } from "../../colors";
import { Loader } from "../loaders";
import { Register } from "../register";
import { getCookie } from "../../utility";
import { GET_USER_FROM_COOKIE } from "../admin/landing";
import { useQuery } from "@apollo/react-hooks";
import loginText from "../../assets/brew-saver-logo-text.png";
import { Copyright } from "../copyright";
import { PaymentForm } from "../payment";
import paymentHeader from "../../assets/bs_payment_header.png";
import { useParams } from "react-router";
import {
  boxShadow,
  Button as Btn,
  EmojiWrapper,
  Label,
  PasswordContainer,
  PasswordInput,
  PasswordToggle,
  Section,
  Text,
  ConfirmButtonWrapper,
  TextInput,
  ConfirmText,
  ToastText,
  Modal,
  ModalWrapper,
  ModalInput,
  ModalLabel,
  LoginSubmitBtn
} from "../shared";

// import { useSessionViewModelContext } from "../session";

const SharedBtn = styled(Btn)`
  background-color: ${colors.primary};
  width: 100%;
`;

const LoginWrapper = styled.div``;

const FormWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: ${colors.memberBg};
  background-image: url(${bubblesBgPng});
  background-size: cover;
`;

const LoginSubmitButton = styled(LoginSubmitBtn)`
  margin: 0 auto;
  margin-top: 1rem;
`;

const LoadContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  border-radius: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const LoginLabel = styled(Label)`
  flex-direction: column;
`;

const RelativeSection = styled(Section)`
  position: relative;
`;

const PasswordWrapper = styled(PasswordContainer)`
  max-width: 100%;
  min-width: 225px;
  width: 100%;
`;

const minWidth = "225px";
const maxWidth = "100%";
const LabelText = styled(Text)`
  align-self: flex-start;
  line-height: 20px;
  margin: 0;
  min-width: unset;
  max-width: unset;
`;

const LoginInput = styled(TextInput)`
  min-width: ${minWidth};
  max-width: ${maxWidth};
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: space-between;
`;

const JoinNowButtonLink = styled.a`
  background-color: ${colors.primary};
  color: white;
  height: 45px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  max-width: 100%;
  min-width: 225px;
  margin: 0 auto;
  margin-top: 1rem;
  width: calc(100% - 40px);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  background-color: white;
  color: ${colors.primary};
  height: 45px;
  width: 200px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  box-shadow: ${boxShadow};
`;

const RegisterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex-wrap: wrap;
`;

const RegisterButton = styled.button`
  color: ${colors.linkColor};
  border: none;
  background: unset;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  text-shadow: 0 0 1px white;
  margin-top: 10px;
`;

const Logo = styled.img`
  max-width: 80vw;
  max-height: 50vh;
  width: auto;
  height: auto;
  margin-bottom: 25px;
`;

const Description = styled.img`
  width: auto;
  height: auto;
  max-width: 90%;
`;

const BtnLink = styled.a`
  text-decoration: none;
  color: unset;
`;

const A = styled.a`
  color: ${colors.linkColor};
`;

const HeaderCongrats = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1rem;
`;

const HeaderMember = styled.h2`
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
`;

const MessageLogin = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const MessageCheers = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const defaultState = {
  email: "",
  password: "",
  passwordResetEmail: "",
  passwordResetCode: "",
  passwordResetPassword: "",
  showResetModal: false,
  showResetCode: false,
  error: "",
  userId: null,
  hasNewError: false,
  isSubmitting: false,
  status: null
};

const dispatch = (state, action) => {
  switch (action.type) {
    case "SET_ERROR": {
      return Object.assign({}, state, action.payload);
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      return newState;
    }
    case "SET_SUBMIT": {
      return {
        ...state,
        isSubmitting: action.payload
      };
    }
    case "SET_FORM": {
      const { status, userId } = action.payload;
      return {
        ...state,
        status,
        userId
      };
    }
    case "RESET_PASSWORD": {
      return {
        ...state,
        password: ""
      };
    }
    case "CONFIRM_RESET_EMAIL": {
      const newState = {
        ...state,
        showResetModal: false,
        showResetCode: true
      };
      return newState;
    }
    case "CONFIRM_CANCEL_RESET_CODE": {
      const newState = {
        ...state,
        showResetModal: false,
        showResetCode: false,
        passwordResetEmail: "",
        passwordResetCode: "",
        passwordResetPassword: ""
      };
      return newState;
    }
    case "SET_SHOW_RESET": {
      const newState = {
        ...state,
        showResetModal: action.payload
      };
      if (!action.payload) {
        newState.passwordResetEmail = "";
      }
      return newState;
    }
    case "SET_SHOW_CODE": {
      const newState = {
        ...state,
        showResetCode: action.payload
      };
      return newState;
    }
    default:
      return { ...state };
  }
};

export const Login = () => {
  const [
    {
      userId,
      email,
      password,
      error,
      hasNewError,
      isSubmitting,
      status,
      passwordResetEmail,
      passwordResetCode,
      passwordResetPassword,
      showResetModal,
      showResetCode
    },
    componentDispatch
  ] = useReducer(dispatch, defaultState);

  const [passwordType, setPasswordType] = useState("password");

  const { loading, fetchError, data } = useQuery(GET_USER_FROM_COOKIE, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });

  if (data?.userFromCookie?.userType) {
    window.location.assign(
      `${window.location.protocol}//${window.location.host}/${data.userFromCookie.userType}`
    );
  }

  const showReset = useCallback(() => {
    componentDispatch({
      type: "SET_SHOW_RESET",
      payload: true
    });
  });

  const hideReset = useCallback(() => {
    componentDispatch({
      type: "SET_SHOW_RESET",
      payload: false
    });
  });

  useEffect(() => {
    if (status !== null && status !== "login") {
      componentDispatch({
        type: "RESET_PASSWORD"
      });
    }
  }, [status]);

  const submitResetCode = useCallback(() => {
    if (passwordResetCode && passwordResetPassword) {
      fetch(`${process.env.REACT_APP_REST_ENDPOINT}/reset`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json"
        },
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify({
          email: passwordResetEmail,
          code: passwordResetCode,
          password: passwordResetPassword
        })
      }).then(
        (response) => {
          response.json().then((data) => {
            if (response.ok) {
              toast.success(data.message || "Password has been reset", {
                position: "bottom-center",
                hideProgressBar: true,
                pauseOnHover: false,
                closeButton: false,
                autoClose: 6500
              });
              componentDispatch({ type: "CONFIRM_CANCEL_RESET_CODE" });
            } else {
              toast.error(data.error || "Password reset failed", {
                position: "bottom-center",
                hideProgressBar: true,
                pauseOnHover: false,
                closeButton: false,
                autoClose: 6500
              });
            }
          });
        },
        (error) => {
          error.json();
        }
      );
    } else {
      toast.error("Code and Password are required", {
        position: "bottom-center",
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: false,
        autoClose: 6500
      });
    }
  });

  const cancelReset = useCallback(() => {
    componentDispatch({
      type: "CONFIRM_CANCEL_RESET_CODE"
    });
  });

  const togglePasswordType = useCallback(() => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }, [passwordType, setPasswordType]);

  useEffect(() => {
    if (hasNewError) {
      let message;
      switch (error) {
        case "Invalid Login": {
          message = "Sorry but the login information you submitted cannot be found. Please try again.";
          break;
        }
        case "Inactive User": {
          message = (
            <span>
              Sorry but we are showing that this account is inactive. To activate this account you must{" "}
              <A name="payment" onClick={setPaymentForm}>
                submit a payment here
              </A>
              .
            </span>
          );
          break;
        }
        default:
          message = "A problem occured while attempting to login";
          break;
      }
      toast.error(
        <EmojiWrapper>
          <Emoji text=":facepalm:" />
          <ToastText>{message}</ToastText>
        </EmojiWrapper>,
        {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false,
          autoClose: 6500
        }
      );
      componentDispatch({
        type: "SET_ERROR",
        payload: { hasNewError: false }
      });
    }
  }, [error, hasNewError]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const setForm = useCallback((e) => {
    componentDispatch({
      type: "SET_FORM",
      payload: { status: e.target.name }
    });
  });

  const setPaymentForm = useCallback(() => {
    componentDispatch({
      type: "SET_FORM",
      payload: { status: "payment", userId }
    });
  }, [userId]);

  const setFormWithName = useCallback(
    (name, userId) => {
      componentDispatch({
        type: "SET_FORM",
        payload: { status: name, userId }
      });
    },
    [userId]
  );

  const setNewUserForm = useCallback(() => {
    componentDispatch({
      type: "SET_FORM",
      payload: { status: "payment", userId: "n/a" }
    });
  }, []);

  let { form } = useParams();

  useEffect(() => {
    if (form === "join") {
      setNewUserForm();
    }
  }, [form, setNewUserForm]);

  const resetPassword = useCallback(() => {
    fetch(`${process.env.REACT_APP_REST_ENDPOINT}/reset`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify({ email: passwordResetEmail })
    }).then((response) => {
      response.json().then(
        (data) => {
          if (response.ok) {
            {
              componentDispatch({
                type: "CONFIRM_RESET_EMAIL"
              });
            }
          } else {
            toast.error(data.error || "Something went wrong", {
              position: "bottom-center",
              hideProgressBar: true,
              pauseOnHover: false,
              closeButton: false,
              autoClose: 6500
            });
          }
        },
        (e) =>
          toast.error(e.error || "Something went wrong", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false,
            autoClose: 6500
          })
      );
    });
  }, [hideReset]);

  const handleLoginPage = useCallback(() => {
    componentDispatch({
      type: "SET_FORM",
      payload: { status: "login", userId }
    });
  }, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    componentDispatch({
      type: "SET_SUBMIT",
      payload: true
    });
    const data = {
      email,
      password
    };
    fetch(`${process.env.REACT_APP_REST_ENDPOINT}/login`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(data)
    }).then(
      (response) => {
        if (response.ok) {
          response.json().then(
            (session) => {
              // sessionDispatch({
              //     type: "SET_SESSION",
              //     payload: session
              // })
              if (session.status === "active") {
                const d = new Date();
                // 30 years
                const expires = new Date(d.getTime() + 946080000000);
                document.cookie = `uuid=${session.sessionID};expires=${expires.toUTCString()};path=/`;
                if (session.type) {
                  window.location.assign(
                    `${window.location.protocol}//${window.location.host}/${session.type}`
                  );
                }
              } else {
                componentDispatch({
                  type: "SET_ERROR",
                  payload: {
                    error: "Inactive User",
                    hasNewError: true,
                    userId: session.id,
                    isSubmitting: false
                  }
                });
              }
            },
            (reason) => {
              componentDispatch({
                type: "SET_ERROR",
                payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
              });
            }
          );
        } else {
          response.json().then(
            (reason) => {
              componentDispatch({
                type: "SET_ERROR",
                payload: { error: reason.error, hasNewError: true, isSubmitting: false }
              });
            },
            (reason) => {
              componentDispatch({
                type: "SET_ERROR",
                payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
              });
            }
          );
        }
      },
      (reason) => {
        componentDispatch({
          type: "SET_ERROR",
          payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
        });
      }
    );
  };

  return (
    <>
      {(status === "login" || status === null) && (
        <FormWrapper key="login">
          <picture>
            {/* <source srcSet={logoWebp} type="image/webp" /> */}
            {/* <source srcSet={logo} type="image/jpeg" /> */}
            <Logo src={logo} />
          </picture>
          <div style={{ display: "flex", justifyContent: "center", minWidth, maxWidth, marginTop: "-30px" }}>
            <Description src={loginText} />
          </div>
          <RelativeSection>
            <Form onSubmit={handleSubmit}>
              <LoginLabel required>
                <LabelText>Email</LabelText>
                <LoginInput
                  required
                  onChange={handleChange}
                  type="text"
                  name="email"
                  placeholder="email@sample.net"
                  value={email}
                />
              </LoginLabel>
              <LoginLabel required>
                <LabelText>Password</LabelText>
                <PasswordWrapper>
                  <PasswordInput
                    required
                    type={passwordType}
                    name="password"
                    value={password}
                    placeholder="password"
                    onChange={handleChange}
                  />
                  <PasswordToggle onClick={togglePasswordType}>
                    <MdRemoveRedEye />
                  </PasswordToggle>
                </PasswordWrapper>
              </LoginLabel>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <LoginSubmitButton type="submit" name="login">
                  Login
                </LoginSubmitButton>
                <LoginSubmitButton type="button" name="payment" onClick={setNewUserForm}>
                  Join
                </LoginSubmitButton>
                <JoinNowButtonLink href="https://brewsaver.net">Website</JoinNowButtonLink>
              </div>
            </Form>
            <RegisterButtonWrapper>
              <RegisterButton name="password_reset" onClick={showReset}>
                Reset Password Here
              </RegisterButton>
            </RegisterButtonWrapper>
            {isSubmitting && <Loader text="Logging In" />}
            {showResetModal && (
              <ModalWrapper>
                <Modal style={{ width: "100%", minHeight: "60%" }}>
                  <ModalLabel>
                    <span>Email</span>
                    <ModalInput
                      type="text"
                      name="passwordResetEmail"
                      value={passwordResetEmail}
                      onChange={handleChange}
                    />
                  </ModalLabel>
                  <ConfirmButtonWrapper style={{ flexDirection: "column" }}>
                    <Button style={{ marginBottom: "5px" }} onClick={resetPassword}>
                      Confirm
                    </Button>
                    <Button onClick={cancelReset}>Cancel</Button>
                  </ConfirmButtonWrapper>
                </Modal>
              </ModalWrapper>
            )}
            {showResetCode && (
              <ModalWrapper>
                <Modal style={{ width: "100%" }}>
                  <ConfirmText>
                    Please check "{passwordResetEmail}" for an email with the code needed to set a new
                    password below.
                  </ConfirmText>
                  <ModalLabel>
                    <span>Reset Code</span>
                    <ModalInput
                      type="text"
                      name="passwordResetCode"
                      value={passwordResetCode}
                      onChange={handleChange}
                    />
                  </ModalLabel>
                  <ModalLabel>
                    <span>New Password</span>
                    <ModalInput
                      type="text"
                      name="passwordResetPassword"
                      value={passwordResetPassword}
                      onChange={handleChange}
                    />
                  </ModalLabel>
                  <ConfirmButtonWrapper style={{ flexDirection: "column" }}>
                    <Button style={{ marginBottom: "5px" }} onClick={submitResetCode}>
                      Confirm
                    </Button>
                    <Button onClick={cancelReset}>Cancel</Button>
                  </ConfirmButtonWrapper>
                </Modal>
              </ModalWrapper>
            )}
          </RelativeSection>
          <Copyright color="white" />
        </FormWrapper>
      )}
      {status === "register" && (
        <FormWrapper>
          <Register name="login" setForm={setFormWithName} loginOnClick={setForm} />{" "}
          <Copyright color="white" />
        </FormWrapper>
      )}
      {status === "payment" && userId !== null && (
        <FormWrapper key="payment">
          <PaymentForm setForm={setFormWithName} userId={userId} loginEmail={email} imageSrc={paymentHeader} />
          <Copyright color="white" />
        </FormWrapper>
      )}
      {status === "congrats" && (
        <FormWrapper>
          <Section>
            <HeaderCongrats>Congratulations!</HeaderCongrats>
            <HeaderMember>You are now a Brew Saver Member!</HeaderMember>
            <MessageLogin>
              Login using your email address and the password you just created for instant access to hundreds
              of drink offers!
            </MessageLogin>
            <MessageCheers>Cheers!!</MessageCheers>
            <SharedBtn onClick={handleLoginPage}>Login Page</SharedBtn>
          </Section>
        </FormWrapper>
      )}

      <ToastContainer autoClose={2000} />
    </>
  );
};
export default Login;
