import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Section, SectionHeader } from "../shared";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_SETTINGS = gql`
  query {
    settings {
      edges {
        node {
          whatsUpMessage
        }
      }
    }
  }
`;

const WhatsUpSection = styled(Section)`
  min-height: 75px;
  max-height: 150px;
  overflow-y: scroll;
  padding-bottom: 10%;
`;

const WhatsUpMessage = styled.span``;

export const MemberWhatsUp = () => {
  const settingsQuery = useQuery(GET_SETTINGS, {
    fetchPolicy: "no-cache"
  });
  const [whatsUpMessage, setWhatsUp] = useState("");

  useEffect(() => {
    if (settingsQuery.data && settingsQuery.data.settings.edges.length > 0) {
      setWhatsUp(settingsQuery.data.settings.edges[0].node.whatsUpMessage);
    }
  }, [settingsQuery.data]);

  return (
    <WhatsUpSection>
      <SectionHeader>What's Up!</SectionHeader>
      <WhatsUpMessage>
        {whatsUpMessage}
      </WhatsUpMessage>
    </WhatsUpSection>
  );
};
