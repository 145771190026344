import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { MdDeleteForever } from "@react-icons/all-files/md/MdDeleteForever";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { colors } from "../../colors";
import { BaseInputStyles, Label, SubmitButton, Text } from "../shared";

const DELETE_MESSAGES = gql`
  mutation ($notificationId: ID!) {
    deleteNotification(notificationId: $notificationId) {
      success
    }
  }
`;

const GET_MESSAGES = gql`
  query {
    allNotifications {
      notificationId
      message
      createdOn
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation ($message: String!, $createdOn: String!) {
    createNotification(message: $message, createdOn: $createdOn) {
      notification {
        message
        createdOn
      }
    }
  }
`;

const UPDATE_MESSAGES = gql`
  mutation ($changes: MessagesInput!, $notificationId: ID!) {
    updateMessages(notificationId: $notificationId, changes: $changes) {
      notification {
        message
        createdOn
      }
    }
  }
`;

const MessageWrapper = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-bottom: 1px solid;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  ${BaseInputStyles}
  width: ${({ width }) => width || "calc(100% - 140px)"};
  max-width: 500px;
  height: 100px;
`;

const defaultState = {
  message: "",
  notifications: [],
  isModalVisible: false,
  isDeleteVisible: false
};

const offerManagerDispatch = (state, action) => {
  switch (action.type) {
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      return newState;
    }
    case "SET_OFFER_TYPE_MANAGER_OPEN": {
      return {
        ...state,
        isOfferTypeManagerVisible: action.payload
      };
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "ADD_OFFER": {
      return {
        ...defaultState,
        identifier: undefined,
        dirtyFields: []
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SHOW_DELETE_MODAL": {
      return {
        ...state,
        isDeleteVisible: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const Messages = () => {
  const messagesQuery = useQuery(GET_MESSAGES, {
    fetchPolicy: "no-cache"
  });
  const [deleteMessages] = useMutation(DELETE_MESSAGES);
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [updateMessages] = useMutation(UPDATE_MESSAGES);
  const [notifications, setNotifications] = useState([]);
  const [state, componentDispatch] = useReducer(offerManagerDispatch, Object.assign(defaultState));

  useEffect(() => {
    if (messagesQuery?.data?.allNotifications) {
      setNotifications(messagesQuery.data.allNotifications);
    }
  }, [messagesQuery.data]);

  const handleSubmit = useCallback(() => {
    createMessage({
      variables: {
        message: state.message,
        createdOn: new Date().toString()
      }
    }).then(
      () => {
        toast.success("Message set successfully.", {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
        messagesQuery.refetch();
      },
      () => {
        toast.error("Something went wrong.", {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
      }
    );
    // }
  }, [updateMessages, createMessage, state]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const handleLineBreak = useCallback(
    (e) => {
      const name = e.target?.dataset["name"];
      const value = state[name] + "<br>";
      handleChange({ target: { name, value } });
    },
    [handleChange, state]
  );

  const handleDelete = useCallback(
    (e) => {
      const id = e.target?.dataset?.id;
      deleteMessages({
        variables: {
          notificationId: id
        }
      }).then(
        () => {
          toast.success("Message deleted successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          messagesQuery.refetch();
        },
        () => {
          toast.error("Something went wrong.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    },
    [deleteMessages]
  );

  return (
    <Wrapper>
      <h2>Manage Messages</h2>
      <section style={{ width: "100%", padding: "1rem" }}>
        <Label required>
          <Text>Message</Text>
          <TextArea required name="message" value={state.message} onChange={handleChange} />
        </Label>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <SubmitButton value="Add Message" onClick={handleSubmit} style={{ justifySelf: "center" }} />
        </div>
      </section>
      <section style={{ width: "100%", padding: "1rem", backgroundColor: colors.secondary }}>
        {notifications.map((notification) => {
          return (
            <MessageWrapper>
              <span>{notification.message}</span>
              <div data-id={notification.notificationId} onClick={handleDelete} title="Delete Message">
                <MdDeleteForever size="2em" style={{ pointerEvents: "none" }} />
              </div>
            </MessageWrapper>
          );
        })}
      </section>

      <ToastContainer />
    </Wrapper>
  );
};
