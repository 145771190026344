import React from "react";
import styled from "styled-components";
import friNo from "../../../assets/Fri-no.jpg";
import friYes from "../../../assets/Fri-yes.jpg";
import monNo from "../../../assets/Mon-no.jpg";
import monYes from "../../../assets/Mon-yes.jpg";
import satNo from "../../../assets/Sat-no.jpg";
import satYes from "../../../assets/Sat-yes.jpg";
import sunNo from "../../../assets/Sun-no.jpg";
import sunYes from "../../../assets/Sun-yes.jpg";
import thuNo from "../../../assets/Thu-no.jpg";
import thuYes from "../../../assets/Thu-yes.jpg";
import tueNo from "../../../assets/Tue-no.jpg";
import tueYes from "../../../assets/Tue-yes.jpg";
import wedNo from "../../../assets/Wed-no.jpg";
import wedYes from "../../../assets/Wed-yes.jpg";

const Image = styled.img``;

const daysYes = {
  0: sunYes,
  1: monYes,
  2: tueYes,
  3: wedYes,
  4: thuYes,
  5: friYes,
  6: satYes
};

const daysNo = {
  0: sunNo,
  1: monNo,
  2: tueNo,
  3: wedNo,
  4: thuNo,
  5: friNo,
  6: satNo
};

const allDays = ["0", "1", "2", "3", "4", "5", "6"];
export const ValidDayImages = ({ validDays }) => {
  return (
    <>
      {allDays.map((day) => (
        <Image key={day} src={validDays.includes(day) ? daysYes[day] : daysNo[day]} alt="valid day" />
      ))}
    </>
  );
};
