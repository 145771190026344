import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ValidDayImages } from ".";
import available from "../../../assets/button-ready.png";
import redeemed from "../../../assets/button-redeemed.png";
import expired from "../../../assets/button-expired.png";
import notyet from "../../../assets/button-notyet.png";
import clink from "../../../assets/redeemed_clink.gif";
import redemptionCheers from "../../../assets/redemption_cheers.png";
import cheersAudio from "../../../assets/cheers.m4a";
import { colors } from "../../../colors";
import {
  getCookie,
  getOfferValidationMessage,
  getExpirationString,
  getNumRedeemedAfterReset
} from "../../../utility";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Emoji, { EmojioneV4 } from "react-emoji-render";
import moment from "moment";
import {
  ConfirmText,
  Button,
  ConfirmButtonWrapper,
  Divider,
  Loader,
  Modal,
  ModalWrapper,
  Section,
  EmojiWrapper,
  ToastText,
  Flex,
  UseDetails,
  UseDetailsAvailable,
  UseDetailsRedeemed
} from "../../shared";

const GET_OFFER_USAGE = gql`
  query($offerId: ID, $userCookie: String) {
    offerAndUsage(offerId: $offerId, userCookie: $userCookie) {
      offerId
      maxValue
      allowedRedemptions
      redemptionPeriod
      redemptionLink
      usesPerDay
      resetDay
      resetTime
      startDate
      endDate
      timeValidStart
      timeValidEnd
      validDays
      redemptions {
        date
        time
      }
    }
  }
`;

const CREATE_REDEMPTION = gql`
  mutation(
    $userCookie: String!
    $venueId: ID!
    $offerId: ID!
    $maxValue: Int!
    $date: String!
    $time: String!
  ) {
    createRedemption(
      userCookie: $userCookie
      venueId: $venueId
      offerId: $offerId
      maxValue: $maxValue
      date: $date
      time: $time
    ) {
      redemption {
        id
        userId
        venueId
        offerId
        date
        time
      }
    }
  }
`;

const sharedStyles = `
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding: 5px 20px;
`;

const VenueName = styled.h1`
  ${sharedStyles}
  font-size: 34px;
  color: ${colors.primary};
`;

const RedemptionStatus = styled.img`
  height: auto;
  width: 80%;
`;

const AvailabilityStatus = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 1.5em;
`;

const ExpirationStatus = styled.span`
  color: red;
  font-weight: bold;
  margin: 1em 0 0.5em 0;
`;

const Header = styled.h2`
  ${sharedStyles}
  font-size: 32px;
`;

const SubHeader = styled.h2`
  ${sharedStyles}
  font-weight: 650;
  font-size: 22px;
`;

const RedemptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
`;

const Pre = styled.pre`
  margin: 0;
`;

const RedemptionText = styled.span`
  ${sharedStyles}
  font-weight: 500;
  font-size: 16px;
  padding: 0;
`;

const Description = styled.h3`
  font-size: 18px;
  font-weight: 600;
  ${sharedStyles}
`;

const Disclaimer = styled.span`
  font-size: 14px;
  ${sharedStyles}
`;

const Image = styled.img`
  max-height: 200px;
  max-width: 80%;
`;

const UseInfo = styled.span`
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
`;

const Dates = styled.div`
  padding: 5px;
`;

const Shrink = keyframes`
  from {
    height: 25vh;
    min-height: 300px;
  }
  to {
    height: 0;
    min-height: 0;
  }
`;

const RedeemModal = styled(Modal)`
  min-height: unset;
`;

const CheersToast = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  height: 25vh;
  font-size: 24px;
  text-align: center;
  background-color: ${colors.secondary};
  animation: ${Shrink} 1.5s linear;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  padding-bottom: 30px;
`;

const MoveLeft = keyframes`
  from {
    left: calc(50% - 100px);
  }
  to {
    left: -100%;
  }
`;

const CheersImgLeft = styled.img`
  width: 200px;
  position: absolute;
  clip: rect(20px, 100px, 160px, 0);
  animation: ${MoveLeft} 2s linear;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
`;

const MoveRight = keyframes`
  from {
    right: calc(50% - 100px);
  }
  to {
    right: -100%;
  }
`;

const CheersImgRight = styled.img`
  width: 200px;
  position: absolute;
  clip: rect(20px, 200px, 160px, 100px);
  animation: ${MoveRight} 2s linear;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
`;

const ShrinkCheersImg = keyframes`
from {
  width: 200px;
}
to {
  width: 0;
}
`;

const CheersImg = styled.img`
  width: 200px;
  position: absolute;
  animation: ${ShrinkCheersImg} 1s linear;
  animation-delay: 2s;
  animation-fill-mode: forwards;
`;

const DaysWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DateText = styled.span`
  color: ${colors.danger};
  padding: 10px;
  font-weight: bold;
`;

const DayImagesWrapper = styled.div`
  display: flex;
  padding: 5px;
`;

const RedeemCancelButtons = styled(Button)`
  font-size: 30px;
  padding: 10px;

  &[disabled] {
    background-color: ${colors.disabled};
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ValueDisplay = styled.div`
  margin: 5px 0;
  padding: 8px 10px;
  background-color: ${colors.goodBackground};
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  color: white;
  width: 250px;
  white-space: nowrap;
  min-width: 60%;
`;

const redemptionImages = {
  "Now Available": available,
  "Not Yet": notyet,
  Expired: expired,
  Checkmark: expired,
  Redeemed: redeemed
};

export const VenueOffer = ({ venueId, venueName, offer, isDisabled }) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(GET_OFFER_USAGE, {
    variables: { offerId: offer.offerId, userCookie: getCookie("uuid") },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });
  const [validationMessage, setValidationMessage] = useState("redemption status");
  const [isModalVisible, setModalVisible] = useState(false);
  const [createRedemption] = useMutation(CREATE_REDEMPTION);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [checkmarkFlag, setCheckmarkFlag] = useState(0);
  const [isRedeeming, setRedeeming] = useState(false);
  const [state, setState] = useState({
    numAvailable: "n/a",
    numRedeemed: "n/a"
  });

  const showModal = useCallback(() => {
    switch (validationMessage.key) {
      case "Now Available": {
        setModalVisible(true);
        break;
      }
      case "Redeemed":
      case "Not Yet": {
        toast.error(
          <EmojiWrapper>
            <Emoji text=":alarm_clock:" />
            <ToastText>This offer is not available for you right now.</ToastText>
          </EmojiWrapper>,
          {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false,
            autoClose: 4000
          }
        );
        break;
      }
      case "Expired": {
        toast.error(
          <EmojiWrapper>
            <Emoji text=":prohibited:" />
            <ToastText>This offer has expired.</ToastText>
          </EmojiWrapper>,
          {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          }
        );
        break;
      }
      default:
        toast.warn(
          <EmojiWrapper>
            <Emoji text=":wrench:" />
            <ToastText>Something is wrong here...</ToastText>
          </EmojiWrapper>,
          {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          }
        );
        break;
    }
  }, [setModalVisible, validationMessage]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  });

  const handleRedemption = useCallback(() => {
    setRedeeming(true);
    const date = new Date();
    createRedemption({
      variables: {
        userCookie: getCookie("uuid"),
        offerId: offer.offerId,
        maxValue: offer.maxValue,
        venueId,
        date: date.toLocaleDateString(),
        time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      }
    }).then(() => {
      toast.info(
        <CheersToast>
          <ToastText style={{ position: "absolute", top: "10px" }}>Your offer has been redeemed!</ToastText>
          <CheersImg src={redemptionCheers} />
          {/* <CheersImgLeft src={clink} />
          <CheersImgRight src={clink} /> */}
          <ToastText
            style={{
              position: "absolute",
              bottom: "30px",
              fontSize: "2em",
              fontWeight: "bold"
            }}
          >
            Cheers!
          </ToastText>
        </CheersToast>,
        {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false,
          autoClose: 2500
        }
      );
      new Audio(cheersAudio).play();
      // window.navigator.vibrate(200);
      refetch();
      setRedeeming(false);
      setModalVisible(false);
      if (offer.redemptionLink) {
        window.open(offer.redemptionLink, "_blank");
      }
    });
  });

  useEffect(() => {
    if (showCheckmark) {
      const timeout = setTimeout(() => {
        setShowCheckmark(false);
      }, 300000);
      return () => window.clearTimeout(timeout);
    }
  }, [showCheckmark]);

  useEffect(() => {
    if (data) {
      const numAllowed = data.offerAndUsage.allowedRedemptions;
      const numRedeemedAfterReset = getNumRedeemedAfterReset(
        data.offerAndUsage.redemptions,
        JSON.parse(data.offerAndUsage.redemptionPeriod)?.value,
        data.offerAndUsage.allowedRedemptions,
        data.offerAndUsage.resetDay
      );
      const numRedeemed = data.offerAndUsage.redemptions.length;
      setState({
        numAvailable: numAllowed - numRedeemedAfterReset,
        numRedeemed: numRedeemed
      });
      const message = getOfferValidationMessage(data.offerAndUsage);
      setValidationMessage(message);
      if (message.key === "Not Yet" && checkmarkFlag > 0) {
        setShowCheckmark(true);
        const timeout = setTimeout(() => {
          setShowCheckmark(false);
        }, 300000);
        return () => window.clearTimeout(timeout);
      }
      if (checkmarkFlag === 0) {
        setCheckmarkFlag(1);
      }
    }
  }, [data]);

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <>
      {loading && <Loader />}
      <Section id={offer.offerId}>
        <VenueName>{venueName}</VenueName>
        <div>
          <RedemptionStatus
            onClick={isDisabled ? undefined : showModal}
            src={showCheckmark ? redemptionImages["Checkmark"] : redemptionImages[validationMessage.key]}
            alt={showCheckmark ? "Not Yet" : validationMessage.message}
          />
        </div>
        <Flex>
          <AvailabilityStatus color={validationMessage.color}>{validationMessage.message}</AvailabilityStatus>
          {validationMessage.key !== "Redeemed" && (
            <ExpirationStatus>{getExpirationString(offer.endDate)}</ExpirationStatus>
          )}
        </Flex>
        <Divider />
        {typeof offer.offerType === "object" ? (
          <Header style={{ fontSize: "26px" }}>{offer?.offerType?.label}</Header>
        ) : (
          <Header style={{ fontSize: "26px" }}>{JSON.parse(offer.offerType).label}</Header>
        )}
        <ValueWrapper>
          {offer?.maxValue !== undefined && parseInt(offer.maxValue) > 0 && (
            <ValueDisplay>{`Save up to $${offer.maxValue} per use`}</ValueDisplay>
          )}
        </ValueWrapper>
        {offer.header && (
          <Header>
            {offer.header.split("<br>").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Header>
        )}
        <Image
          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${offer.image}`}
          onError={(e) => (e.target.style.display = "none")}
        />
        {offer.subheader && (
          <SubHeader>
            {offer.subheader.split("<br>").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </SubHeader>
        )}
        {offer.description && (
          <Description>
            {offer.description.split("<br>").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Description>
        )}
        {offer.disclaimer && (
          <Disclaimer>
            {offer.disclaimer.split("<br>").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </Disclaimer>
        )}
        <Divider />
        <RedemptionWrapper>
          {data && data.offerAndUsage.redemptionPeriod && (
            <RedemptionText>{`${data.offerAndUsage.allowedRedemptions} ${JSON.parse(
              data.offerAndUsage.redemptionPeriod
            )
              .label.replace(/([A-Z]+)/g, " $1")
              .replace(/([A-Z][a-z])/g, " $1")}`}</RedemptionText>
          )}
          {data && data.offerAndUsage.usesPerDay && (
            <RedemptionText>
              <Pre> - </Pre>
              {data.offerAndUsage.usesPerDay} Per Day
            </RedemptionText>
          )}
        </RedemptionWrapper>
        <RedemptionWrapper>
          <RedemptionText>{state.numAvailable} Available</RedemptionText>
          <RedemptionText>
            <Pre> - </Pre>
            {state.numRedeemed} Redeemed
          </RedemptionText>
        </RedemptionWrapper>
        <Divider />
        {data?.offerAndUsage?.timeValidStart && data?.offerAndUsage?.timeValidEnd && (
          <Dates>
            <DateText>{`${moment(data.offerAndUsage.timeValidStart, "HH:mm").format("hh:mm A")} - ${moment(
              data.offerAndUsage.timeValidEnd,
              "HH:mm"
            ).format("hh:mm A")}`}</DateText>
          </Dates>
        )}
        <DaysWrapper>
          <DateText>Days Available</DateText>
          <DayImagesWrapper>
            {data && <ValidDayImages validDays={data.offerAndUsage.validDays} />}
          </DayImagesWrapper>
        </DaysWrapper>
        <Dates>
          <DateText>{`${new Date(offer.startDate).toLocaleDateString()} - ${new Date(
            offer.endDate
          ).toLocaleDateString()}`}</DateText>
        </Dates>
      </Section>
      {isModalVisible && (
        <ModalWrapper>
          <RedeemModal>
            <ConfirmText>Confirm Redemption</ConfirmText>
            <ConfirmButtonWrapper>
              <RedeemCancelButtons onClick={hideModal}>Cancel</RedeemCancelButtons>
              <RedeemCancelButtons disabled={isRedeeming} onClick={handleRedemption}>
                Redeem
              </RedeemCancelButtons>
            </ConfirmButtonWrapper>
          </RedeemModal>
        </ModalWrapper>
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};
