import React, { createContext, useContext, useReducer } from "react";

export const anchorViewModel = createContext();
anchorViewModel.displayName = "Anchor Context";
export const useAnchorContext = () => {
  return useContext(anchorViewModel);
};

const defaultState = {
  anchor: null
};

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return Object.assign({}, state, action.payload);
    }
    default:
      return { ...state };
  }
};

export const AnchorProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <anchorViewModel.Provider value={[state, dispatch]}>{children}</anchorViewModel.Provider>;
};
