import React, { useCallback, useEffect, useReducer, useState } from "react";
import Emoji from "react-emoji-render";
import { MdRemoveRedEye } from "@react-icons/all-files/md/MdRemoveRedEye";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { colors } from "../../colors";
import { Loader } from "../loaders";
import {
  EmojiWrapper,
  Label,
  Modal,
  ModalWrapper,
  PasswordContainer,
  PasswordInput,
  PasswordToggle,
  Section,
  Text,
  TextInput,
  ToastText
} from "../shared";

const Header = styled.h2`
  margin: 0 0 10px 0;
  color: ${colors.primary};
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const LoginButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size 18px;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const PasswordWrapper = styled(PasswordContainer)`
  max-width: 100%;
  min-width: 225px;
  width: 100%;
`;

const LoginButton = styled.button`
  color: ${colors.linkColor};
  border: none;
  background: unset;
  text-decoration: underline;
  font-weight: bold;
  text-shadow: 0 0 1px white;
  font-size 18px;

`;

const SubmitBtn = styled.button`
  background-color: ${colors.primary};
  color: white;
  height: 45px;
  width: 200px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  max-width: 100%;
  min-width: 225px;
  margin: 10px auto;
  width: calc(100% - 40px);
`;

const LoginLabel = styled(Label)`
  flex-direction: column;
`;

const minWidth = "225px";
const maxWidth = "100%";
const LabelText = styled(Text)`
  align-self: flex-start;
  line-height: 20px;
  margin: 0;
  min-width: unset;
  max-width: unset;
`;

const LoginInput = styled(TextInput)`
  min-width: ${minWidth};
  max-width: ${maxWidth};
  width: 100%;
`;

const RegisterSection = styled(Section)`
  max-height: 90%;
  overflow-y: scroll;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 50%;
`;

const defaultState = {
  email: "",
  password: "",
  first: "",
  last: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  error: "",
  hasNewError: false,
  isSubmitting: false,
  showThankYou: false
};

const dispatch = (state, action) => {
  switch (action.type) {
    case "SET_ERROR": {
      return Object.assign({}, state, action.payload);
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      return newState;
    }
    case "SET_SUBMIT": {
      return {
        ...state,
        isSubmitting: action.payload
      };
    }
    case "CLOSE_THANK_YOU": {
      return {
        ...state,
        email: "",
        password: "",
        first: "",
        last: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        showThankYou: false
      };
    }
    case "RESET_FORM": {
      return {
        ...state,
        email: "",
        password: "",
        first: "",
        last: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zipCode: ""
      };
    }
    case "SET_FORM": {
      return {
        ...state,
        status: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const Register = ({ setForm, loginOnClick }) => {
  const [
    {
      email,
      password,
      first,
      last,
      phone,
      address,
      city,
      state,
      zipCode,
      error,
      hasNewError,
      isSubmitting,
      showThankYou
    },
    componentDispatch
  ] = useReducer(dispatch, defaultState);

  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordType = useCallback(() => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }, [passwordType, setPasswordType]);

  useEffect(() => {
    if (hasNewError) {
      switch (error) {
        case "Email has already registered": {
          toast.error(
            <EmojiWrapper>
              <Emoji text=":facepalm:" />
              <ToastText>
                It looks like this email is already in use
                <LoginButton name="login" onClick={loginOnClick}>
                  Login Here
                </LoginButton>
              </ToastText>
            </EmojiWrapper>,
            {
              position: "bottom-center",
              hideProgressBar: true,
              pauseOnHover: false,
              closeButton: false,
              autoClose: 4000
            }
          );
          break;
        }
        default:
          if (error) {
            toast.error(
              <EmojiWrapper>
                <Emoji text=":no_entry:" />
                <ToastText>A problem occurred when creating a Brew Saver</ToastText>
              </EmojiWrapper>,
              {
                position: "bottom-center",
                hideProgressBar: true,
                pauseOnHover: false,
                closeButton: false,
                autoClose: 4000
              }
            );
          }
          break;
      }
      componentDispatch({
        type: "SET_ERROR",
        payload: { hasNewError: false }
      });
    }
  }, [error, hasNewError]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const handleCloseThankYou = useCallback((e) => {
    componentDispatch({
      type: "CLOSE_THANK_YOU"
    });
    // if (loginOnClick) {
    //   loginOnClick(e);
    // }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    componentDispatch({
      type: "SET_SUBMIT",
      payload: true
    });
    const data = {
      email,
      password,
      first,
      last,
      phone,
      address,
      city,
      state,
      zipCode,
      userType: "member",
      userStatus: "inactive",
      notes: "",
      created: new Date().toString(),
      createSession: true
    };
    fetch(`${process.env.REACT_APP_REST_ENDPOINT}/register`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify(data)
    }).then(
      (response) => {
        componentDispatch({
          type: "SET_SUBMIT",
          payload: false
        });
        if (response.ok) {
          response.json().then(
            (session) => {
              // they aren't being logged in on register, dont need to create session
              // const d = new Date();
              // const numHours = 4;
              // const expires = d.setTime(d.getTime() + numHours * 60 * 60 * 1000);
              // // TODO - expires
              // document.cookie = `uuid=${session.sessionID};expires=${expires};path=/`;
              // window.location.assign(`${window.location.protocol}//${window.location.host}/member`);
              toast.success(
                "Thank you for registering with Brew Saver. Please Submit a Payment to Activate the Membership.",
                {
                  position: "bottom-center",
                  hideProgressBar: true,
                  pauseOnHover: false,
                  closeButton: false
                }
              );
              setForm("payment", session.id);
            },
            (reason) => {
              componentDispatch({
                type: "SET_ERROR",
                payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
              });
            }
          );
        } else {
          response.json().then(
            (reason) => {
              componentDispatch({
                type: "SET_ERROR",
                payload: { error: reason.error, hasNewError: true, isSubmitting: false }
              });
            },
            (reason) => {
              componentDispatch({
                type: "SET_ERROR",
                payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
              });
            }
          );
        }
      },
      (reason) => {
        componentDispatch({
          type: "SET_ERROR",
          payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
        });
      }
    );
  };

  return (
    <>
      <RegisterSection>
        {/* <TopWrapper>
          <Logo src={logo} />
        </TopWrapper> */}
        <Header>Brew Saver Registration</Header>
        <Form onSubmit={handleSubmit}>
          <LoginLabel required>
            <LabelText>Email</LabelText>
            <LoginInput
              required
              value={email}
              onChange={handleChange}
              type="text"
              placeholder="email@sample.net"
              name="email"
            />
          </LoginLabel>
          <LoginLabel required>
            <LabelText>Password</LabelText>
            <PasswordWrapper>
              <PasswordInput
                required
                type={passwordType}
                name="password"
                value={password}
                onChange={handleChange}
                placeholder="password"
              />
              <PasswordToggle onClick={togglePasswordType}>
                <MdRemoveRedEye />
              </PasswordToggle>
            </PasswordWrapper>
          </LoginLabel>
          <LoginLabel required>
            <LabelText>First</LabelText>
            <LoginInput
              required
              value={first}
              onChange={handleChange}
              type="text"
              placeholder="John"
              name="first"
            />
          </LoginLabel>
          <LoginLabel required>
            <LabelText>Last</LabelText>
            <LoginInput
              required
              value={last}
              onChange={handleChange}
              type="text"
              placeholder="Brewer"
              name="last"
            />
          </LoginLabel>
          <LoginLabel>
            <LabelText>Phone</LabelText>
            <LoginInput
              value={phone}
              onChange={handleChange}
              type="text"
              placeholder="(720) 303-8855"
              name="phone"
            />
          </LoginLabel>
          <LoginLabel>
            <LabelText>Street Address</LabelText>
            <LoginInput
              value={address}
              onChange={handleChange}
              type="text"
              placeholder="street address"
              name="address"
            />
          </LoginLabel>
          <LoginLabel>
            <LabelText>City</LabelText>
            <LoginInput value={city} onChange={handleChange} type="text" placeholder="city" name="city" />
          </LoginLabel>
          <LoginLabel>
            <LabelText>State</LabelText>
            <LoginInput value={state} onChange={handleChange} type="text" placeholder="state" name="state" />
          </LoginLabel>
          <LoginLabel>
            <LabelText>Zip Code</LabelText>
            <LoginInput
              value={zipCode}
              onChange={handleChange}
              type="text"
              placeholder="zip code"
              name="zipCode"
            />
          </LoginLabel>
          <SubmitBtn type="submit" name="Register">
            Register
          </SubmitBtn>
          {/* <PaymentLink
            href="https://divotgolfdeals.com/brew-saver-membership/"
            target="_blank"
            onClick={handleCloseThankYou}
          >
            Submit Payment Here
          </PaymentLink> */}
          <LoginButtonWrapper>
            Already a member?{" "}
            <LoginButton name="login" onClick={loginOnClick}>
              Login Here
            </LoginButton>
          </LoginButtonWrapper>
        </Form>
      </RegisterSection>
      {isSubmitting && <Loader text="Preparing a new Brew Saver!" />}
      {showThankYou && (
        <ModalWrapper>
          <Modal>
            <span style={{ margin: "40px 5px", fontSize: "20px", fontWeight: 600, textAlign: "center" }}>
              Thank you for registering with Brew Saver. Please Submit a Payment to Activate the Membership.
            </span>
          </Modal>
        </ModalWrapper>
      )}
    </>
  );
};
