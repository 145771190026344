import React, { useCallback, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Section, Button } from "../../shared";
import { VenueOffer } from "./";
import { useVenueNavContext } from "../";
import styled from "styled-components";
import { colors } from "../../../colors";
import { Loader } from "../../loaders";

const GET_VENUE_AND_OFFERS = gql`
  query($venueId: ID!) {
    venueOffers(venueId: $venueId) {
      venueId
      phone
      website
      address
      city
      state
      zipCode
      name
      logo
      image
      moreInfo
      offers {
        offerId
        header
        subheader
        redemptionLink
        description
        disclaimer
        image
        startDate
        endDate
        maxValue
        offerType
      }
    }
  }
`;

const VenueImage = styled.img`
  display: block;
  margin: auto;
  padding-bottom: 10px;
  width: 95%;
`;

const VenueName = styled.h2`
    color: ${colors.primary};
    margin: 0;
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const VenueInfo = styled.span`
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const CloseButton = styled(Button)`
  background-color: ${colors.primary};
  margin-top: 1rem;
  width: 100%; 
`

const MoreInfoButton = styled.button`
  color: ${colors.linkColor};
  border: none;
  background: unset;
  text-decoration: none;
  font-size: 16px;
  text-shadow: 0 0 1px white;
  margin-top: 10px;
`;

const VenueLink = styled.a`
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 2px;
    line-height: 16px;
    color: ${colors.linkColor};
    word-break: break-all;
`;

const Phone = styled.a`
  text-decoration: none;
  color: inherit;
  word-break: break-all;
`;

const VenueWebsite = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  margin: 2px;
  color: ${colors.linkColor};
  word-break: break-all;
`;
const height = "70px";
const StylePopUp = styled.section`
  margin: 0;
  padding: 10px 5%;
  box-sizing: border-box; 
  width: 100%;
  transition: all 1s;
  height: calc(100vh - ${height});
  background-color: ${colors.secondaryTransparent};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  max-width: 100%;
  top: ${height};
  border-top: 1px solid ${colors.secondaryOffset};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const formatURL = (url) => {
  const prefix = "http://";
  const https = "https://";
  const subStr = url.substr(0, prefix.length);

  if (subStr !== prefix && subStr !== https) {
    url = prefix + url;
  }
  return url;
};

export const MemberVenue = () => {
  const [open, setOpen] = useState(false);
  let { venueId } = useParams();
  const { loading, error, data } = useQuery(GET_VENUE_AND_OFFERS, {
    variables: { venueId },
    fetchPolicy: "no-cache"
  });
  const [, venueNavDispatch] = useVenueNavContext();

  useEffect(() => {
    venueNavDispatch({
      type: "SET_VENUE",
      payload: venueId
    });
  }, [venueId]);

  const openPopUp = useCallback(()=>{
    setOpen(true);
  },[])

  const closePopUp = useCallback(()=>{
    setOpen(false);
  },[])
  const MoreInfo = useMemo(()=>{
      console.log({data});
      if (data && data.venueOffers.moreInfo){        
        return <MoreInfoButton onClick={openPopUp}>More Info</MoreInfoButton> 
      }
      return null;
    },[data])

    const PopUp = useMemo(()=>{
      console.log({data});
      if (open){        
        return <StylePopUp>
          <Section>

         
          {data.venueOffers.moreInfo}
          <CloseButton onClick = {closePopUp}>Close</CloseButton>
          </Section>
        </StylePopUp>;  

      }
      return null;
    },[open, data])
  if (error) {
    return <div>{error.message}</div>;
  }  

  // if (loading) {
  //     return <div>loading...</div>
  // }

  return (
    <>
      {loading && <Loader />}
      {data && (
        <>
          <Section>
            <VenueImage key="logo" src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${data.venueOffers.logo}`} />
            <VenueImage
              key="image"
              src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${data.venueOffers.image}`}
            />
            <VenueName key="name">{data.venueOffers.name}</VenueName>

            <Phone href={`tel:${data.venueOffers.phone}`}>{data.venueOffers.phone}</Phone>
            <VenueInfo key="website">
              <VenueWebsite href={formatURL(data.venueOffers.website)} target="_blank">
                {data.venueOffers.website}
              </VenueWebsite>
            </VenueInfo>
            <VenueLink
              target="_blank"
              key="address"
              href={`https://maps.google.com/?q=${data.venueOffers.address},
              ${data.venueOffers.city},
              ${data.venueOffers.state} ${data.venueOffers.zipCode}`}
            >
              {`${data.venueOffers.address},`}
              <br />
              {`${data.venueOffers.city},
              ${data.venueOffers.state} ${data.venueOffers.zipCode}`}
            </VenueLink>
            {MoreInfo}            
          </Section>
          {PopUp} 
          {data.venueOffers.offers.map((offer) => {
            // const endDate = new Date(offer.endDate);
            // if (endDate < new Date()) {
            //   return <React.Fragment key={offer.offerId}></React.Fragment>;
            // }
            return (
              <VenueOffer
                key={offer.offerId}
                venueId={data.venueOffers.venueId}
                venueName={data.venueOffers.name}
                offer={offer}
              />
            );
          })}
        </>
      )}
    </>
  );
};
