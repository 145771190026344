import React, { useCallback, useEffect, useState, memo } from "react";
import styled from "styled-components";
import { useSelectViewModelContext } from ".";
import { colors } from "../../colors";

const height = "40px";
const width = "40px";
export const CheckboxColumn = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width};
  height: ${height};
  box-sizing: border-box;
  border-left: 1px solid ${colors.tableHeaderFont};
  border-right: 1px solid ${colors.tableHeaderFont};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  ${({ sticky }) => sticky && `position: sticky; top: ${sticky};`}
`;

// &:hover::after {
//   content: "";
//   position: absolute;
//   width: ${({ scrollWidth }) => (scrollWidth ? scrollWidth : "0px")};
//   height: ${height};
//   left: 0;
//   box-shadow: inset 0 0 5px rgba(255, 165, 0.5);
// }

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 32px;
  justify-content: center;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? "salmon" : "papayawhip")};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  box-shadow: ${(props) => (props.isActive ? `0 0 0 3px ${colors.secondary}` : "")};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const TableCheckbox = ({ table, id, isActive }) => {
  const [selectData, selectDispatch] = useSelectViewModelContext();
  const [checked, setChecked] = useState(selectData[table].includes(id));

  useEffect(() => setChecked(selectData[table].includes(id)), [selectData, table, id]);

  const handleChange = useCallback((e) => {
    selectDispatch({
      type: "TOGGLE_SELECT",
      payload: { table, id }
    });
  });

  return (
    <Wrapper>
      <label id={`${table}_checkbox${id}`} aria-label={`Select row id ${id}`}>
        <CheckboxContainer>
          <HiddenCheckbox onChange={handleChange} checked={checked} />
          <StyledCheckbox checked={checked} isActive={isActive}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
      </label>
    </Wrapper>
  );
};

export default memo(TableCheckbox);
