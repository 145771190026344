import React, { useCallback } from "react";
import { useClientContext } from "./";
import { VenueOffer } from "../";
import styled from "styled-components";
import bubblesBgPng from "../../assets/bubbles.png";
import { Button } from "../shared";

const DisplayBackground = styled.div`
  position: fixed;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisplayWrapper = styled.div`
  position: fixed;
  background-image: url(${bubblesBgPng});
  background-size: cover;
  height: 600px;
  width: 400px;
  max-height: 70vh;
  max-width: 80vw;
  z-index: 3;
  overflow-y: scroll;
`;

export const OfferDisplay = () => {
  const [clientData, clientDispatch] = useClientContext();

  const handleClose = useCallback(() => {
    clientDispatch({
      type: "SET_OFFER",
      payload: { activeOffer: null }
    });
  }, [clientDispatch]);

  return (
    <>
      {clientData.activeOffer && (
        <DisplayBackground>
          <DisplayWrapper>
            <VenueOffer
              key={clientData.activeOffer.offerId}
              venueId={clientData.activeOffer.venues[0].value}
              venueName={clientData.activeOffer.venues[0].label}
              offer={clientData.activeOffer}
              isDisabled={true}
            />
            <Button
              onClick={handleClose}
              style={{ width: "100%", position: "sticky", bottom: "-1px", margin: "0" }}
            >
              Close
            </Button>
          </DisplayWrapper>
        </DisplayBackground>
      )}
    </>
  );
};
