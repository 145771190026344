import React, { useCallback, useState } from "react";
import { IoMdDownload } from "@react-icons/all-files/io/IoMdDownload";
import { TableButton } from "../table";
import { useTableViewModelContext } from "./tableContext";
import { useSelectViewModelContext } from "./selectContext";

const buttonSize = "2em";
export const ExportButton = ({ table }) => {
  const [tableData] = useTableViewModelContext();
  const [selectData] = useSelectViewModelContext();
  const [id, setId] = useState(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5)
  );

  const exportData = useCallback(() => {
    fetch(`${process.env.REACT_APP_EXPORT_ENDPOINT}/${table}/${id}`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(tableData[table].rows)
    }).then((response) => {
      if (response.ok) {
        document.getElementById(`export_${table}`).click();
        setId(
          Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5)
        );
      }
    });
  }, [tableData, table, id]);

  return (
    <>
      <TableButton
        key={`${table}_export`}
        icon={<IoMdDownload size={buttonSize} />}
        text="Export Data"
        handleClick={exportData}
      />
      <a
        style={{ dislpay: "none" }}
        id={`export_${table}`}
        href={`${process.env.REACT_APP_DOWNLOAD_ENDPOINT}/${table}/${id}.csv`}
        target="_blank"
      ></a>
    </>
  );
};
