import styled from "styled-components";
import { Rotate } from "../shared";
import logo from "../../assets/brewsaver-logo-bubbles.png";
import { colors } from "../../colors";

export const LogoLoader = styled.img.attrs({ src: logo })`
  height: ${({ size }) => size || 100}px;
  width: ${({ size }) => size || 100}px;
  animation: ${Rotate} 5s linear infinite;
  color: ${colors.secondary};
`;
