import React, { useEffect, useState } from "react";
import styled from "styled-components";

const FileInput = styled.input.attrs({ type: "file" })`
  visibility: hidden;
  height: 0;
  width: 0;
  position:absolute;
`;

export const FileUpload = ({ id, name, onChange }) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (onChange && file) {
      onChange(file);
    }
  }, [onChange, file]);

  const handleUpload = (e) => {
    if (e.currentTarget.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setFile(fileReader.result);
      }
      fileReader.readAsText(e.currentTarget.files[0]);
    }
  }
  return (
    <FileInput id={id} name={name} onChange={handleUpload} />
  );
};
