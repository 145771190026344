import React, { useCallback, useEffect, memo } from "react";
import styled from "styled-components";
import { colors } from "../../colors";
import { CheckboxColumn, TableCheckbox } from ".";
import { useTableViewModelContext } from "./tableContext";
import { useSelectViewModelContext } from "./selectContext";
import { FaSort } from "@react-icons/all-files/fa/FaSort";

const height = "40px";
export const HeaderColumn = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: ${height};
  box-sizing: border-box;
  border-left: 1px solid ${colors.tableHeaderFont};
  border-right: 1px solid ${colors.tableHeaderFont};
  background-color: ${colors.tableHeaderBg};
  color: ${colors.tableHeaderFont};
  cursor: pointer;
  z-index: 2;
`;

const SortIcon = styled(FaSort)`
  position: absolute;
  right: 8px;
`;

const TableHeader = ({ table, isSelectable, ids }) => {
  const [tableData, tableDispatch] = useTableViewModelContext();
  const [, selectDispatch] = useSelectViewModelContext();

  useEffect(() => {
    selectDispatch({
      type: "SET_IDS",
      payload: { table, ids }
    });
  }, [table, ids, selectDispatch]);

  const handleClick = useCallback(
    (accessor) => {
      tableDispatch({
        type: "SET_LOADING",
        payload: { table, isLoading: true }
      });
      setTimeout(() => {
        tableDispatch({
          type: "SORT_BY",
          payload: { table, accessor }
        });
      }, 100);
    },
    [table, tableDispatch]
  );

  return (
    // <Wrapper>
    <>
      {isSelectable && (
        <CheckboxColumn
          sticky={"0px"}
          key={`${table}_cell_checkbox_header`}
          backgroundColor={colors.tableHeaderBg}
        >
          <TableCheckbox id={-1} table={table} numRows={tableData[table].rows.length} />
        </CheckboxColumn>
      )}
      {tableData[table].columns.map((column) => (
        <HeaderColumn key={`${table}_header_${column.header}`} onClick={() => handleClick(column.accessor)}>
          {column.header}
          <SortIcon size={15} />
        </HeaderColumn>
      ))}
    </>
    // </Wrapper>
  );
};

export default memo(TableHeader);
