import React, { createContext, useContext, useReducer } from "react";
import { columns } from "./tableContext";

export const filterViewModel = createContext();
filterViewModel.displayName = "Filter Context";
export const useFilterViewModelContext = () => {
  return useContext(filterViewModel);
};

const defaultState = {};

Object.keys(columns).map(key => {
  defaultState[key] = {};
  defaultState[key].isVisible = true;
  defaultState[key].values = {}
  columns[key].forEach(col => defaultState[key].values[col.header] = "");
});

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "TOGGLE_FILTER": {
      const table = action.payload;
      const newState = { ...state };
      newState[table].isVisible = !newState[table].isVisible;
      return newState;
    }
    case "SET_VALUE": {
      const { table, name, value } = action.payload;
      const newState = { ...state };
      newState[table].values[name] = value;
      return newState;
    }
    case "RESET_FILTERS": {
      const table = action.payload;
      const newState = { ...state };
      newState[table].values = {};
      columns[table].forEach(col => newState[table].values[col.header] = "");
      return newState;
    }
    default:
      return { ...state };
  }
};

export const FilterProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <filterViewModel.Provider value={[state, dispatch]}>{children}</filterViewModel.Provider>;
};
